import axios from "axios";

export async function getCurrentAccount(id) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/cuenta_corriente/?afiliado_id=${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getAccountSearch(id, dates) {
	const body = {
		fecha_hasta: dates.finish_date,
		afiliado_id: id,
		fecha_desde: dates.initial_date
	}
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/cuenta_corriente/search`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getPaysDebt() {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/cuenta_corriente/get_pagos`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function createPay(body) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/pago`, body);
		return response.data
	} catch (error) {
		return { error }
	}
}