import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useSnackBar } from '../../../../hooks/useSnackBar';
import { getPlansService, getCountries, getStates, getDepartament, getLocation } from '../../../../redux/api/utils';
import { ButtonGreen } from '../../../common/ButtonsGenerics';
import InputFile from '../../../common/InputFile';
import PageLoading from '../../../common/PageLoading';
import InfoUser from '../../../common/user/InfoUser';
import { SnackBarGeneric } from "../../../common/SnackBarGeneric";
import { createPharmacyOrder } from '../../../../redux/api/phamarcy';
import { GridLayotCol } from '../../../common/Grid';
import { Text } from '../../../common/Text';
import { getDataUser } from '../../../../redux/api/affiliate';
import { useHistory } from 'react-router-dom';

const PharmacyForm = () => {

    const [affiliate, setAffiliate] = useState(null);
    const [plans, setPlans] = useState(null)

    const [loading, setloading] = useState(false)

    const history = useHistory()

    // Form
    const [form, setForm] = useState(null)
    const { user, family } = useSelector(state => state.auth)

    // Inputs
    const [zip, setzip] = useState(null)
    const [countries, setCountries] = useState(null)
    const [states, setStates] = useState(null)
    const [departaments, setDepartaments] = useState(null)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)


    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    const affiliates = [user, ...family]

    useEffect(() => {
        getAffiliate(user.afiliado_id)
        getCountriesService()
    }, [])

    useEffect(() => {
        getPlans(affiliate?.afiliado_id)
    }, [affiliate?.afiliado_id])
    

    useEffect(() => {
        if ((!!form?.zip || !!zip) && (form?.zip?.length >= 4 || zip.length >= 4)) {
            getDataByZip(form?.zip || zip)
            if (form.country) {
                getStatesService(form.country.id)
                if (form.state) {
                    getDepartamentsService(form.state)
                }
            }
        }
    }, [zip, form?.country?.id, form?.zip])

    const handleChangeAffiliate = (event) => {
        const user = affiliates.find(aff => aff.afiliado_id === event.target.value)
        setAffiliate(user);
        getAffiliate(user.afiliado_id)
    };

    const getPlans = async (id) => {
        const request = await getPlansService(id || user.afiliado_id)
        if (!request.error) {
            setPlans(request)
        }
    }

    const getAffiliate = async id => {
        try {
            const { data } = await getDataUser(id)
            setForm({...data, state: data.state.id})
        } catch (error) {
            console.log(error);
        }
    }

    const getCountriesService = async () => {
        try {
            const { data } = await getCountries()
            setCountries(data)
        } catch (error) {
            console.log(error);
        }
    }

    const getStatesService = async (id_country) => {
        try {
            const { data } = await getStates(id_country)
            setStates(data)
        } catch (error) {
            alert("No se encuentran las provincias correspondientes")
            setStates([])
            console.log(error);
        }
    }

    const getDepartamentsService = async (id_state) => {
        try {
            const { data } = await getDepartament(id_state)
            setDepartaments(data)
        } catch (error) {
            alert("No se encuentran los departamentos correspondientes")
            setDepartaments([])
            console.log(error);
        }
    }

    const getDataByZip = async (cp) => {
        try {
            const { data } = await getLocation(cp)
            setForm({
                ...form,
                departamento: data[0]?.departamento?.id,
                state: data[0]?.departamento?.state?.id,
            })            
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }

    const handleSelectState = e => {
        setForm({ ...form, departamento: null, [e.target.name]: e.target.value })
        getDepartamentsService(e.target.value)
    }

    const handleSelectDepartament = e => {
        setForm({ ...form, [e.target.name]: e.target.value })
    }

    const handleSumbitByCP = (e) => {
        if (e.target.value.length <= 4) {
            setzip(e.target.value)
            setForm(state => ({ ...state, zip: e.target.value }))
        }
    }


    const handleSubmit = async () => {
        setloading(true)
        const data = {
            departamento_entrega_id: form.departamento,
            afiliado_id: form.id,
            plan_id: form.plan,
            zip_entrega: form.zip,
            medico_solicitante: form.medical,
            comentario: form.message || '',
            direccion_entrega: form.direccion,
            country_entrega_id: form.country.id,
            state_entrega_id: form.state,
            localidad_entrega_id: 0,
            images: file,
            // image_name: fileName,
            email_entrega: form?.email,
            phone_entrega: form?.phone
        }

        const request = await createPharmacyOrder(data)
        if (request.error) {
            openSnackBar(
                "error",
                "Error al crear la solicitud de medicamento"
            );
        }
        else {
            openSnackBar(
                "success",
                "Se creó correctamente"
            );
            setTimeout(() => {
                history.push('../request-pharmacy')
            }, 1500);
        }
        setloading(false)
    }

    const handleB64File = (files) => {
        // setFileName(name)
        setFile(files)
    }

    
    if (form === null) {
        return <PageLoading h="200px" w="300px" />
    }

    return <PharmacyFormWrapper>
        <Left>
            <FormControlFake>
                <InputLabel id="1">Seleccione un afiliado</InputLabel>
                <SelectFake
                    labelId="1"
                    value={affiliate?.afiliado_id || affiliates[0]?.afiliado_id}
                    onChange={handleChangeAffiliate}
                    name="affiliate"
                >
                    {affiliates.map(member => <MenuItem value={member.afiliado_id}>{`${member.nombre} ${member.apellido}`}</MenuItem>)}
                </SelectFake>
            </FormControlFake>
            {affiliate !== null && (
                <InfoUser affiliate={affiliate} />
            )}
            <br />
            <br />
            <Text color="#444444" fontWeight="600">DATOS DE CONTACTO</Text>
            <GridLayotCol gap=".5rem" col="1fr 1fr">
                <TextField label="Teléfono" name="mobile" onChange={handleChange} fullWidth value={form.mobile || form.phone || ''} />
                <TextField label="E-mail" name="email" onChange={handleChange} fullWidth value={form.email || ''}/>
            </GridLayotCol>
            <br />
            <Text color="#444444" fontWeight="600">DIRECCIÓN DE ENTREGA</Text>
            <br/>
            <GridLayotCol gap=".5rem" col="1fr 1fr">
                <TextField label="Dirección" name="direccion" onChange={handleChange} fullWidth value={form.direccion}/>
                <TextField
                    fullWidth
                    value={form?.zip}
                    onChange={handleSumbitByCP} label="Código Postal" />
            </GridLayotCol>

            <GridLayotCol gap=".5rem" col="1fr 1fr 1fr">

                {countries && (
                    <FormControl fullWidth>
                        <InputLabel>Pais</InputLabel>
                        <Select disabled name="country" value={form.country ? form.country.id : 0} placeholder="Pais">
                            {countries.map(country => <MenuItem value={country.id}>{country.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                )}
                {states && (
                    <FormControl>
                        <InputLabel>Provincia</InputLabel>
                        <Select onChange={handleSelectState} name="state" value={form.state || states[0].id} placeholder="Provincia">
                            {states.map(state => <MenuItem value={state.id}>{state.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                )}
                {departaments && <FormControl>
                    <InputLabel>Departamento</InputLabel>
                    <Select onChange={handleSelectDepartament} name="departamento" value={form.departamento || departaments[0].id} placeholder="Departamento">
                        {departaments.map(dpto => <MenuItem value={dpto.id}>{dpto.name}</MenuItem>)}
                    </Select>
                </FormControl>}
            </GridLayotCol>

        </Left>
        <Right>
            <Text color="#444444" fontWeight="600">PLAN</Text>
            <Grid>
                {!!plans && <FormControlFake>
                    <InputLabel id="1">Plan</InputLabel>
                    <SelectFake
                        disabled={!plans}
                        labelId="1"
                        name="plan"
                        value={form.plan}
                        onChange={handleChange}
                    >
                        {plans.map(plan => <MenuItem value={plan.id}>{`${plan.name}`}</MenuItem>)}
                    </SelectFake>
                </FormControlFake>}

            </Grid>
            <Text color="#444444" fontWeight="600">COMENTARIO</Text>
            <TextFieldFake
                id="date"
                label="Ingresa tu comentario"
                type="text"
                multiline
                rows={4}
                onChange={handleChange}
                name="message"
                value={form.message}
            />
            <Text color="#444444" fontWeight="600">RECETA MÉDICA</Text>
            <InputFile handleB64File={handleB64File} />
        </Right>
        <Footer>
            {!loading ?
                (<ButtonGreen 
                onClick={handleSubmit} 
                disabled={
                    !!form.id && (!!form.phone || !!form.mobile) && !!form.email && !!form.direccion && !!form.zip
                    && !!form.country && !!form.state && !!form.departamento && !!form.plan && !!file ? false : true
                }>
                    Solicitar pedido farmacia
                </ButtonGreen>) :
                (<PageLoading h="auto" w="100%" />)}
        </Footer>
        <SnackBarGeneric
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </PharmacyFormWrapper>
}

export default PharmacyForm;

const PharmacyFormWrapper = styled.div`
    width: auto;
    display: grid;
    display: grid;
    grid-template-areas: "left right"
                         "footer footer";
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 800px) {
        grid-template-columns: 1fr;
        grid-template-areas: "left" 
                            "right"
                            "footer";
    }
`
const Grid = styled.div`
    width: auto;
    display: grid;
    gap: 1rem;
    margin: 1rem 0;
`

const Left = styled.div`
    grid-area: left;
    width: 100%;
`

const Right = styled.div`
    grid-area: right;
    width: 100%;
`

const FormControlFake = styled(FormControl)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
    margin: 1rem 0;
`

const Footer = styled.div`
    grid-area: footer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`