// import { TextField } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { createPay, getPaysDebt } from '../../../redux/api/account'
import PageLoading from '../../common/PageLoading'
// import { Autocomplete } from '@material-ui/lab';
import TableGeneric from '../../common/TableGeneric'
import { format } from '../../../helpers/DateFormat.helper'
import { ButtonBlue, ButtonGreen, ButtonRed } from '../../common/ButtonsGenerics'
import DeleteIcon from '@material-ui/icons/Delete';
import { formatCurrency } from '../../../helpers/currency.format.helper'

const PaysDebt = (props) => {

    const { handlePagePay } = props

    const columns = [
        // { id: 'id', label: 'N° Factura' },
        { id: 'name', label: 'Descripción' },
        { id: 'fecha', label: 'Fecha' },
        { id: 'total', label: 'Monto total' },
        { id: 'residual', label: 'Saldo' },
        { id: 'acciones', label: 'Acciones' },
    ]

    const [pays, setPays] = useState(null)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        !pays && getPays()
    }, [pays])


    const getPays = async () => {
        const req = await getPaysDebt()
        if (req.error) {
            alert('Ocurrio un error')
        }
        if (req[0]?.statusCode === 404) {
            setPays([])
            return
        }
        // const req = [
        //     {
        //         id: 1,
        //         nombre: 'pago',
        //         fecha: new Date().toDateString(),
        //         amount_residual_signed: 1500.0,
        //         amount_total_signed: 2000.0
        //     },
        //     {
        //         id: 2,
        //         nombre: 'pago 2',
        //         fecha: new Date().toDateString(),
        //         amount_residual_signed: 1500.0,
        //         amount_total_signed: 2000.0
        //     }
        // ]
        setPays(req)
    }

    const handleSelect = value => {
        const result = pays.map(item => {
            if (item.id === value.id) {
                return { ...item, checked: true }
            }
            else{
                return item
            }
        })
        setTotal(total + value.amount_residual_signed)
        setPays(result)
    }

    const handleQuit = value => {
        const result = pays.map(item => {
            if (item.id === value.id) {
                return { ...item, checked: false }
            }
            else{
                return item
            }
        })
        setTotal(total - value.amount_residual_signed)
        setPays(result)
    } 


    const tableRender = (array) => {
        if (array) {
            return array.map(item => ({
                ...item,
                fecha: format(item.fecha),
                total: `$${formatCurrency(item.amount_total_signed.toFixed(2))}`,
                residual: `$${formatCurrency(item.amount_residual_signed.toFixed(2))}`,
                acciones: !!item.checked ? <ButtonRed onClick={() => handleQuit(item)}>Quitar</ButtonRed> : <ButtonGreen onClick={() => handleSelect(item)}>Seleccionar</ButtonGreen>
            }))
        }
        else{
            return array
        }
    }

    const handleSubmit = async () => {
        setLoading(true)
        const body = { facturas: pays.filter(item => !!item.checked).map(item => item.id) }
        const req = await createPay(body)
        setLoading(false)
        if (req.error) {
            alert('Ocurió un error al generar el pago')
            return
        }
        else{
            handlePagePay(req.amount_total.toFixed(2), req.id)
        }
    }

    if (!pays || loading) {
        return <PageLoading w="100%" h="100px"/>
    }

    return (
        <PaysWrapper>
            {/* <Autocomplete
                options={pays}
                getOptionLabel={(option) => `$${option.amount_total_signed} | ${option.descripcion}`}
                disabled={!pays}
                onChange={(e, value) => handlePay(e, value)}
                renderInput={(params) => <TextField {...params} value={pays[0]} label="Factura *" variant="standard" />}
            /> */}
            <TableGeneric columns={columns} rows={tableRender(pays)} pageSize={10} />
            <TotalContainer>Total a pagar: ${formatCurrency(total.toFixed(2))}</TotalContainer>
            {pays.filter(item => !!item.checked).length > 0 && total > 0 && <ButtonBlue onClick={handleSubmit}>Ir a pagar</ButtonBlue>}
        </PaysWrapper>
    )
}

export default PaysDebt

const PaysWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 22px;
`

const TotalContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: bold;
`