import axios from "axios";

export function getSpecialties() {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/especialidad/`);
}

export async function getSpecialtiesDamsu() {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/especialidad/especialidades_damsu/`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export function searchProviderBySpeciality(id, provider) {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador/?especialidad_id=${id}&prestador=${provider}`);
}

export async function searchProvider(type, especialidad, prestador) {
	try {
		let endpoint = type === 1 ? `?especialidad_id=${especialidad}` : `?prestador_name=${prestador}`
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/agenda/${endpoint}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getProvider() {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/prestador/`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getProfesional() {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/profesional/`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getProfesionalByProvider(id) {
	try {
		const response = await axios.post(`${process.env.REACT_APP_DANSUAPI}/private/profesional/search?prestador_id=${id}`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export async function getPlansService(id) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/afiliado/${id}/plans`);
		return response.data
	} catch (error) {
		return { error }
	}
}

export function getCountries() {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/country`);
}

export function getStates(id_country) {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/states/search?id=${id_country}`);
}

export function getDepartament(id_state) {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/departamentos/search?id=${id_state}`);
}

export function getLocation(cp) {
	return axios.get(`${process.env.REACT_APP_DANSUAPI}/private/localidad/search?codigo_postal=${cp}`);
}

export async function getTypeDocuments() {
	try {
		const response = await axios.get(`${process.env.REACT_APP_DANSUAPI}/private/tipo_documento`);
		return response.data
	} catch (error) {
		return { error }
	}
}