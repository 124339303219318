import React from "react";
import { Redirect, Switch } from "react-router-dom"
import Affiliate from "./pages/affiliate/Affiliate";
import ConsumptionHistory from "./pages/pharmacy/ConsumptionHistory";
import Contact from "./pages/contact/Contact";
import CurrentAccount from "./pages/current-account/CurrentAccount";
import Login from "./pages/auth/Login";
import Orders from "./pages/orders/Orders";
import Shifts from "./pages/shifts/Shifts";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import StockPharmacy from "./pages/pharmacy/StockPharmacy";
import Audit from "./pages/audit/Audit";
import Logout from "./pages/auth/Logout";
import Internal from "./pages/providers/Internal";
import External from "./pages/providers/External";
import CurrentDebt from "./pages/current-account/CurrentDebt";
import RequestPharmacy from "./components/templates/pharmacy/RequestPharmacy";
import Refunds from "./pages/refunds/Refunds";
import Register from "./pages/auth/Register";
import ResetPassword from "./pages/auth/ResetPassword";
import StatusPay from "./pages/pay/StatusPay";
import ResetPasswordToken from "./pages/auth/ResetPasswordToken";


const Routes = () => {
    return (
        <Switch>
            {/* Rutas privadas */}
            <PrivateRoute path="/affiliate" exact component={Affiliate} />
            <PrivateRoute path="/shifts" exact component={Shifts} />
            <PrivateRoute path="/orders" exact component={Orders} />
            <PrivateRoute path="/request-pharmacy" exact component={RequestPharmacy} />
            <PrivateRoute path="/consumption-history" exact component={ConsumptionHistory} />
            <PrivateRoute path="/stock-medicines" exact component={StockPharmacy} />
            <PrivateRoute path="/audit" exact component={Audit} />
            <PrivateRoute path="/historical-debt" exact component={CurrentAccount} />
            <PrivateRoute path="/current-debt" exact component={CurrentDebt} />
            <PrivateRoute path="/contact" exact component={Contact} />
            <PrivateRoute path="/auth/logout" exact component={Logout} />
            <PrivateRoute path="/external-provider" exact component={External} />
            <PrivateRoute path="/internal-provider" exact component={Internal} />
            <PrivateRoute path="/refunds" exact component={Refunds} />
            <PrivateRoute type={'noLayout'} path="/statusPay" exact component={StatusPay} />

            {/* Rutas publicas */}
            <PublicRoute path="/auth/login" exact component={Login} />
            <PublicRoute path="/auth/register" exact component={Register} />
            <PublicRoute path="/auth/reset" exact component={ResetPassword} />
            <PublicRoute path="/auth/reset/:token" exact component={ResetPasswordToken} />

            {/* Redirecciones */}
            <Redirect path="/" to="/affiliate" />
            <Redirect path="*" to="/not-found" />
        </Switch>
    )
}

export default Routes;