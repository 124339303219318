import axios from "axios";

export function loginUser(payload) {
	return axios.post(`${process.env.REACT_APP_DANSUAPI}/public/afiliado/login`, {	
		numero_documento: parseInt(payload.user,10),
		password: payload.password,
	});
}

export async function registerForm(body) {
	try {
		const request = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/user/search_user`, body);
		return request.data
	} catch (error) {
		return { error }
	}
}

export async function registerPassword(body, token) {
	try {
		const config = {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}
		console.log(config);
		const request = await axios.post(`${process.env.REACT_APP_DANSUAPI}/public/user/create_user`, body, config);
		return request.data
	} catch (error) {
		return { error }
	}
}